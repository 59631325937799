const advanceCookieElement = document.querySelector('.advance-cookie-banner');

if (advanceCookieElement) {
  const cookieInputs = document.querySelectorAll('.advance-cookie-banner__modal input');
  const essentialCookie = document.querySelectorAll('.is-necessary input');

  if (!Cookies.get('necessary_cookie')) {
    advanceCookieElement.classList.add('cookie-not-set');
  }

  const setCookie = (cookieName) => {
    Cookies.set(cookieName, true, { expires: 30 });
  };

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  const closeCookieModal = () => {
    const modalEle = document.querySelector('.advance-cookie-banner');
    if (modalEle) {
      modalEle.classList.remove('cookie-not-set');
      toggleBodyScrollLock(false);
    }
  };

  const toggleCookieInput = (elem, toggle) => {
    elem.checked = toggle;
  };

  // *******************************************************************************
  // Set cookies
  // *******************************************************************************

  attachEvent('.btn--accept-cookie, .btn--confirm-choice', 'click', (ev) => {
    if (ev.target.classList.contains('btn--accept-cookie')) {
      cookieInputs.forEach((item) => {
        const cookieName = item.getAttribute('data-cookie');
        setCookie(cookieName);
        toggleCookieInput(item, true);
      });
    } else {
      cookieInputs.forEach((item) => {
        const cookieName = item.getAttribute('data-cookie');
        if (item.checked) {
          setCookie(cookieName);
        } else {
          toggleCookieInput(item, false);
          Cookies.remove(cookieName);
        }
      });
    }
    reloadPage();
    closeCookieModal();
    ev.preventDefault();
  });

  // *******************************************************************************
  // Accept all cookies
  // *******************************************************************************

  attachEvent('.btn--accept-all', 'click', (ev) => {
    cookieInputs.forEach((item) => {
      const cookieName = item.getAttribute('data-cookie');
      setCookie(cookieName);
      toggleCookieInput(item, true);
    });
    reloadPage();
    closeCookieModal();
    advanceCookieElement.classList.remove('cookie-not-set');
    ev.preventDefault();
  });

  // *******************************************************************************
  // Toggle advance setting
  // *******************************************************************************

  const openCookieSetting = () => {
    const dropdown = advanceCookieElement.querySelector('.advance-cookie-banner__modal');
    advanceCookieElement.classList.toggle('is-open-setting');
    slideToggle(dropdown, 300, 'block');
  };

  attachEvent('.btn--cookie-setting', 'click', (ev) => {
    openCookieSetting();
    ev.preventDefault();
  });

  // *******************************************************************************
  // Open cookie modal
  // *******************************************************************************

  attachEvent('.btn--open-cookie-modal', 'click', (ev) => {
    advanceCookieElement.classList.add('cookie-not-set');
    openCookieSetting();
    ev.preventDefault();
  });

  // *******************************************************************************
  // Check already set cookie on load
  // *******************************************************************************
  cookieInputs.forEach((item) => {
    const cookieName = item.getAttribute('data-cookie');
    const cookieStatus = Cookies.get(cookieName);

    // eslint-disable-next-line no-param-reassign
    toggleCookieInput(item, !!cookieStatus);
  });
  essentialCookie.forEach((input) => {
    toggleCookieInput(input, true);
  });
}
