const toggleModal = (list, targetModal, parentCard, toggle) => {
  list.classList.toggle('hide', toggle);
  targetModal.classList.toggle('show', toggle);
  parentCard.classList.toggle('is-active', toggle);
};

const scrollToItem = (element, scrollBehavior) => {
  const elementRect = element.getBoundingClientRect();
  const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
  const topOffset = elementRect.top + scrollTop - 30;
  window.scrollTo({
    top: topOffset,
    behavior: scrollBehavior,
  });
};

attachEvent('.product-card .btn', 'click', (el) => {
  const parent = el.target.closest('.products-list');
  const parentCard = el.target.closest('.product-card');
  const modalIndex = parentCard.getAttribute('data-target');
  const list = parent.querySelector('.products-list__list');
  const targetModal = parent.querySelector(`[data-modal="${modalIndex}"]`);
  toggleModal(list, targetModal, parentCard, true);
  el.preventDefault();

  if (windowWidth() < breakpointMd) {
    scrollToItem(targetModal, 'smooth');
  }
});

attachEvent('.product-modal__back-button', 'click', (el) => {
  const parent = el.target.closest('.products-list');
  const parentModal = el.target.closest('.product-modal');
  const list = parent.querySelector('.products-list__list');
  const modalIndex = parentModal.getAttribute('data-modal');
  const targetCard = parent.querySelector(`[data-target="${modalIndex}"]`);
  toggleModal(list, parentModal, targetCard, false);

  if (windowWidth() < breakpointMd) {
    scrollToItem(targetCard, 'smooth');
  }
  el.preventDefault();
});

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    const modalElem = document.querySelectorAll('.product-modal');
    const list = document.querySelectorAll('.products-list__list');

    modalElem.forEach((el) => {
      el.classList.remove('show');
    });

    list.forEach((el) => {
      el.classList.remove('hide');
    });
  }
});
