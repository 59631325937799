// Mobile menu toggle
const toggleMenuClass = (toggle) => {
  document.body.classList.toggle('is-open-menu', toggle);
};

attachEvent('.hamburger', 'click', (ev) => {
  ev.preventDefault();
  const isOpen = document.body.classList.contains('is-open-menu');
  toggleMenuClass(!isOpen);
});

// Toggle active class on scroll
// const menuLinks = document.querySelectorAll('.menu__link');
// menuLinks.forEach((link) => {
//   const parentElem = link.closest('.menu__item');
//   const linkId = link.getAttribute('href');

//   if (linkId && linkId !== '#') {
//     const sectionElem = document.querySelector(
//       linkId.startsWith('http://') || linkId.startsWith('https://') ? `[href="${linkId}"]` : linkId,
//     );

//     if (sectionElem) {
//       gsap.timeline({
//         scrollTrigger: {
//           trigger: sectionElem,
//           start: 'top center',
//           end: 'bottom center',
//           scrub: true,
//           toggleClass: { targets: parentElem, className: 'is-active' },
//         },
//       });
//     }
//   }
// });
