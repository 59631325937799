const heroSlider = document.querySelectorAll('[data-slider="hero-slider"]');

const heroSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  watchOverflow: true,
  loop: true,
  effect: 'fade',
  speed: 600,
  fadeEffect: {
    crossFade: true,
  },
  // autoplay: {
  //   delay: 5000,
  // },
};

heroSlider.forEach((el) => {
  const heroSliderInit = new Swiper(el, heroSliderOptions);

  attachEvent('.hero-slider__nav-arrow.arrow-left', 'click', () => {
    heroSliderInit.slidePrev(600);
  });

  attachEvent('.hero-slider__nav-arrow.arrow-right', 'click', () => {
    heroSliderInit.slideNext(600);
  });

  attachEvent('.hero-slider__pagination-item', 'click', (el) => {
    const slideIndex = el.target.getAttribute('data-slide');
    heroSliderInit.slideTo(slideIndex, 600);
  });
});
