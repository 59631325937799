/* eslint no-param-reassign: "error" */
/* eslint-disable no-new */

// *******************************************************************************
// GLOBALS
// *******************************************************************************
axios.defaults.headers['Content-Type'] = 'multipart/form-data';

// *******************************************************************************
// 0.1 - BREAKPOINT VARS
// *******************************************************************************
const breakpointSm = 767;
const breakpointMd = 1023;
const breakpointLg = 1171;

// *******************************************************************************
// 0.2 - VARIOUS FUNCTIONS
// *******************************************************************************

// Return Window Width
function windowWidth() {
  return window.innerWidth || document.documentElement.clientWidth;
}

const isFractal = () =>
  !!document.querySelector('.Frame') ||
  window.location.href.includes('localhost:3010') ||
  window.location.href.includes('/preview/components/');

const isLocal = () => window.location.href.includes('localhost');

// *******************************************************************************
// 0.3 - FUNCTIONS
// *******************************************************************************

// Attach Events
function attachEvent(selector, event, handler) {
  document.addEventListener(
    event,
    (ev) => {
      let { target } = ev;
      for (; target && target !== document; target = target.parentNode) {
        if (target.matches(selector)) {
          try {
            handler.call(target, ev);
          } catch (e) {
            console.error(e);
          }
          break;
        }
      }
    },
    false,
  );
}

const euro = Intl.NumberFormat('sl-SI', {
  style: 'currency',
  currency: 'EUR',
  useGrouping: false,
});

// Animate
function animate(elementToAnimate, type) {
  switch (type) {
    case 'show':
      elementToAnimate.classList.add('is-visible');
      elementToAnimate.classList.remove('is-hidden');
      break;
    case 'hide':
      elementToAnimate.classList.add('is-hidden');
      elementToAnimate.classList.remove('is-visible');
      break;
    default:
      break;
  }
}

// Validate email input
const validateEmailInput = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// *******************************************************************************
// 0.4 - DEBOUNCE FUNCTION
// *******************************************************************************

function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// *******************************************************************************
// 0.5 - SLIDE FUNCTIONS
// *******************************************************************************

const slideUp = (target, duration) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = `${duration}ms`;
  target.style.boxSizing = 'border-box';
  target.style.height = `${target.offsetHeight}px`;
  // eslint-disable-next-line no-unused-expressions
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

const slideDown = (target, duration, displayType) => {
  target.style.removeProperty('display');

  let { display } = window.getComputedStyle(target);

  if (display === 'none') {
    display = displayType;
  }
  target.style.display = display;

  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  // eslint-disable-next-line no-unused-expressions
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = `${duration}ms`;
  target.style.height = `${height}px`;
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

const slideToggle = (target, duration, displayType) => {
  /* Slide-toggle logic */

  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration, displayType);
  }
  return slideUp(target, duration, displayType);
};

// *******************************************************************************
// 0.7 - Smooth Scroll
// *******************************************************************************

const smoothScroll = new scrollToSmooth('a', {
  targetAttribute: 'data-scrollto',
  duration: 1000,
  durationRelative: false,
  durationMin: false,
  durationMax: false,
  easing: easeInOutCubic,
  offset: '76',
  onScrollStart: (data) => {
    // do something
    document.documentElement.style.scrollBehavior = 'initial';
    toggleMenuClass(false);
  },
  onScrollUpdate: (data) => {
    // do something
  },
  onScrollEnd: (data) => {
    // do something
    document.documentElement.style.scrollBehavior = 'smooth';
  },
});
smoothScroll.init();

// *******************************************************************************
// 0.9 - Match Height / Equal height : https://dev.to/dhruvangg/equal-height-in-vanilla-javascript-49ch
// *******************************************************************************

function setHeight(el, val) {
  if (typeof val === 'function') val = val();

  if (typeof val === 'string') el.style.height = val;
  else el.style.height = `${val}px`;
}

const equalheight = function (container) {
  let currentTallest = 0;
  let currentRowStart = 0;
  const rowDivs = new Array();
  let $el;
  const topPosition = 0;

  Array.from(document.querySelectorAll(container)).forEach((el, i) => {
    el.style.height = 'auto';
    const topPostion = el.offsetTop;
    if (currentRowStart !== topPostion) {
      let currentDiv = 0;

      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        setHeight(rowDivs[currentDiv], currentTallest);
      }
      rowDivs.length = 0;
      currentRowStart = topPostion;
      currentTallest = parseFloat(getComputedStyle(el, null).height.replace('px', ''));
      rowDivs.push(el);
    } else {
      rowDivs.push(el);
      currentTallest =
        currentTallest < parseFloat(getComputedStyle(el, null).height.replace('px', ''))
          ? parseFloat(getComputedStyle(el, null).height.replace('px', ''))
          : currentTallest;
    }

    let currentDiv = 0;
    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      setHeight(rowDivs[currentDiv], currentTallest);
    }
  });
};

window.addEventListener('load', () => {
  equalheight('.equal-height');
});
window.addEventListener('resize', () => {
  setTimeout(() => {
    equalheight('.equal-height');
  });
});

// *******************************************************************************
// 0.10 - ENVIRONMENT BADGE
// *******************************************************************************
const envBadge = document.querySelector('.sdt-env__badge');
if (envBadge) {
  envBadge.style.top = '150px';
  envBadge.addEventListener('mouseover', (ev) => {
    const { style } = ev.target;
    if (style.top) {
      style.removeProperty('top');
      style.bottom = '250px';
    } else {
      style.removeProperty('bottom');
      style.top = '150px';
    }
  });
}

// *******************************************************************************
// 0.11 - Get nonce for static pages
// *******************************************************************************
const getNonceForAction = async (formAction) => {
  if (isFractal()) {
    return Promise.resolve('');
  }

  try {
    const formDataNonce = {
      action: 'getNonceField',
      nonce_action: formAction,
    };

    const response = await axios.post(localData.apiURL, formDataNonce, {});
    return response.data.data.nonce;
  } catch (error) {
    console.error(error);
  }
};

// *******************************************************************************
// Form helper
// *******************************************************************************
const joinActiveItems = (checkedItems) =>
  Array.from(checkedItems)
    .map((checkedItem) => checkedItem.getAttribute('value'))
    .join('|');

const getFormValue = (form, fv) => {
  const formData = {};

  Object.keys(fv.getFields()).forEach((key) => {
    const element = form.querySelector(`[data-fv-name="${key.replace(/\[\]/g, '')}"]`);
    const parentElem = element.closest('[data-input-group-parent]');

    if ((element.type === 'radio' && parentElem) || (element.type === 'checkbox' && parentElem)) {
      const selectedInputs = parentElem.querySelectorAll('input:checked');
      const itemValues = joinActiveItems(selectedInputs);
      formData[key] = itemValues || '';
    } else {
      formData[key] = fv.getElementValue(key, element);
    }
  });

  return { inputs: JSON.stringify(formData) };
};

/**
 * @typedef {Object} AxiosOptions - Options object for Axios request.
 * @property {Object} headers - Request headers.
 * @property {string} headers.Content-Type - Content type for the request.
 * @property {string} [method] - HTTP method (e.g., GET, POST, PUT, etc.).
 * @property {string} [url] - Request URL.
 * @property {Object} [params] - URL parameters.
 * @property {Object} [data] - Request payload data.
 * @property {Object} [auth] - Authentication credentials (e.g., { username: 'user', password: 'pass' }).
 * @property {number} [timeout] - Request timeout in milliseconds.
 * @property {boolean} [withCredentials] - Indicates whether cross-site Access-Control requests
 *                                         should be made using credentials.
 */

/**
 * @typedef {Object} Options - Options object for the onFormSubmit function.
 * @property {AxiosOptions} [axiosOptions] - Options for the Axios request.
 * @property {Object} formData - The form data to submit.
 * @property {string} [recaptchaKey] - Recaptcha key.
 * @property {string} [recaptchaAction] - Recaptcha action.
 * @property {number} [timeout] - Timeout for the request in milliseconds.
 * @property {string} [url] - Url of the request.
 * @property {boolean} [forceError] - You should use this to force an form error, to test UI on error.
 */

/**
 * Simulates a form submission using Axios.
 * @param {Options} [options] - Additional options for the request.
 * @returns {Promise} - A promise that resolves to the Axios response.
 */

const onFormSubmit = async (options = { formData: {} }) => {
  // Default values
  if (!options.formData.lang) {
    options.formData.lang = localData.lang;
  }

  // FE / Preview submission of the form without API
  if (isFractal()) {
    console.warn('Fractal - Simulating request');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (options.forceError) {
          reject(new Error('Simulated error'));
          return;
        }

        console.warn('Simulated success, FormData:', options.formData);
        resolve();
      }, options.timeout || 250);
    });
  }

  if (options.recaptchaKey && options.recaptchaAction) {
    const token = await grecaptcha.execute(options.recaptchaKey, { action: options.recaptchaAction });
    const nonce = await getNonceForAction(options.recaptchaAction);

    options.formData = { ...options.formData, action: options.recaptchaAction, nonce, token };
  }

  // Make a request to the API
  return axios.post(options.url || localData.siteURL, options.formData, {
    ...options.axiosOptions,
  });
};

// *******************************************************************************
// Add scrollbar width
// *******************************************************************************
const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  document.documentElement.style.setProperty('--scroll-width', `${scrollbarWidth}px`);
};

getScrollbarWidth();

window.addEventListener('resize', () => {
  getScrollbarWidth();
});

// *******************************************************************************
// Toggle class functions
// *******************************************************************************
const toggleBodyScrollLock = (toggle) => {
  document.body.classList.toggle('body-scroll-locked', toggle);
};

// *******************************************************************************
// Redirect page and smooth scroll
// *******************************************************************************

// Wait for the page to fully load
window.addEventListener('load', () => {
  // Get the hash value from the URL
  const { hash } = window.location;

  // Check if the hash value is not empty
  if (hash) {
    // Find the target element with the matching ID
    const targetElement = document.querySelector(hash);

    // Check if the target element exists
    if (targetElement) {
      // Calculate the scroll position to the target element
      const scrollPosition = targetElement.getBoundingClientRect().top + window.scrollY;

      // Scroll to the target element smoothly
      setTimeout(() => {
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }, 300);
    }
  }
});

// *******************************************************************************
// Lightbox
// *******************************************************************************

Fancybox.bind('[data-fancybox]', {});

// *******************************************************************************
// Move elements from left/right
// *******************************************************************************

const handleIntersection = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('in-viewport');
    }
  });
};

const animationObserver = new IntersectionObserver(handleIntersection, {
  root: null,
  rootMargin: '0px 0px -25% 0px',
  threshold: 0,
});

const animateElements = document.querySelectorAll('.moveAnimation');
animateElements.forEach((section) => {
  animationObserver.observe(section);
});
