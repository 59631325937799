const checkYouTubeCookie = (parent) => {
  if (Cookies.get('youtube_cookie')) {
    parent.classList.add('load-youtube');
  }
};

// Check for youtube cookie on button click
attachEvent('.video-module__button', 'click', (el) => {
  const parent = el.target.closest('.video-module');
  checkYouTubeCookie(parent);
});

// Check for youtube cookie initially
const videoModuleElem = document.querySelectorAll('.video-module');
videoModuleElem.forEach((elem) => {
  checkYouTubeCookie(elem);
});
