// *******************************************************************************
// Add class to body after some scroll
// *******************************************************************************
function addScrollingClass() {
  const topOffset = document.documentElement.scrollTop || document.body.scrollTop;
  document.body.classList.toggle('is-scrolled', topOffset > 30);
}

addScrollingClass();

let ticking = false;
document.addEventListener('scroll', () => {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      addScrollingClass();
      ticking = false;
    });
    ticking = true;
  }
});

let lastScrollTop = 0;
window.addEventListener(
  'scroll',
  () => {
    const isScrolledTop = window.pageYOffset || document.documentElement.scrollTop;
    document.body.classList.toggle('is-scrolled-up', isScrolledTop < lastScrollTop);
    lastScrollTop = isScrolledTop <= 0 ? 0 : isScrolledTop;
  },
  false,
);
